import React from "react";
import Layout from "../layouts/sk";
import { Container } from "reactstrap";
import MapWithAnOverlayView from "../components/OverlayMap";
import SEO from "../components/seo.js";
import { graphql } from "gatsby"
class Parcels extends React.Component {
 
  render() {
    return (
      <div>
        <Layout location={this.props.location}>
          <Container fluid className="p-0">
            <div>
              <MapWithAnOverlayView
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `90vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                onSelect={(parcel) => this.setState({ onSelect: parcel })}
                location={this.props.location}
              />
            </div>
          </Container>
          <SEO title="Budovy" />
        </Layout>
      </div>
    );
  }
}
export default Parcels;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`